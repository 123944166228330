export const data = [
  {
    title: 'New York',
    map: 'https://goo.gl/maps/b4EJmUcKd3vSvUxd8',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679516824/contact-page/NewYork-collage.png',
    city: 'New York',
    street: '228 Park Avenue South',
    postal: '10003',
    country: 'USA',
  },
  {
    title: 'Hamburg',
    map:
      'https://www.google.com/maps/search/?api=1&query=Große+Bleichen+1,+20354+Hamburg,+Niemcy',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679516824/contact-page/Hamburg-collage.png',
    city: 'Hamburg',
    street: 'Große Bleichen 1-3',
    postal: '20354',
    country: 'Germany',
  },
  {
    title: 'Amsterdam ',
    map:
      'https://www.google.com/maps/search/?api=1&query=Vijzelstraat+68,+1017+HL+Amsterdam,+Holandia',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679997252/contact-page/Amsterdam-collage.png',
    city: 'Amsterdam',
    street: 'Vijzelstraat 68-78',
    postal: '1017 HL',
    country: 'The Netherlands',
  },
  {
    title: 'Gliwice',
    map:
      'https://www.google.com/maps/search/?api=1&query=Zwycięstwa+52,+44-100+Gliwice',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679516824/contact-page/Poland-collage.png',
    city: 'Gliwice',
    street: 'Zwycięstwa 52',
    postal: '44-100',
    country: 'Poland',
  },
  {
    title: 'Wroclaw',
    map:
      'https://www.google.com/maps/search/?api=1&query=Wyspa+Slodowa+7,+50-266+Wroclaw',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679516824/contact-page/Poland-collage.png',
    city: 'Wroclaw',
    street: 'Wyspa Słodowa 7',
    postal: '50-266',
    country: 'Poland',
  },
  {
    title: 'Warsaw',
    map:
      'https://www.google.com/maps/search/?api=1&query=Krucza+50,+00-025+Warszawa',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679516824/contact-page/Poland-collage.png',
    city: 'Warsaw',
    street: 'Krucza 50',
    postal: '00-025',
    country: 'Poland',
  },
  {
    title: 'Cracow',
    map:
      'https://www.google.com/maps/search/?api=1&query=Kurniki+9,+31-156+Cracow',
    image:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1679516824/contact-page/Poland-collage.png',
    city: 'Cracow',
    street: 'Kurniki 9',
    postal: '31-156',
    country: 'Poland',
  },
];

export const contactPersonData = [
  {
    country: 'USA',
    name: 'Klaudia Cembor',
    position: 'client partner',
    email: 'klaudia.cembor@boldare.com',
    avatar: 'klaudia-cembor',
  },
  {
    country: 'Germany',
    name: 'Matt Hallmann',
    position: 'International Growth & Expansion',
    email: 'matt.hallmann@boldare.com',
    avatar: 'matt-hallmann',
  },
  {
    country: 'Poland',
    name: 'Mateusz Pachnik',
    position: 'client partner',
    email: 'mateusz.pachnik@boldare.com',
    avatar: 'mateusz-pachnik',
  },
  {
    country: 'The Netherlands',
    name: 'Matt Hallmann',
    position: 'International Growth & Expansion',
    email: 'matt.hallmann@boldare.com',
    avatar: 'matt-hallmann',
  },
];
