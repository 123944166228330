import React from 'react';
import {
  Link,
  Section,
  SectionHeader,
  Paragraph,
  FooterPhones,
} from 'components';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';
import ContactUsForm from '../ContactUsForm';
import WordAnimation from '../WordAnimation';

import './ContactBanner.scss';

const ContactBanner = React.forwardRef(
  ({ enableDecorations, color, sidebarColor, widerDisplay }, ref) => (
    <Section
      additionalClass={'contact-banner'}
      color={color}
      spaceless
      fullwidth
    >
      <Row className={'no-gap contact-banner__row'}>
        <Col md={9} lg={8} xl={8}>
          <>
            {enableDecorations && (
              <div className={'contact-banner__content-rectangle'} />
            )}
            <div
              ref={ref}
              className={`${
                widerDisplay
                  ? 'contact-banner__content--wider'
                  : 'contact-banner__content'
              }`}
            >
              <SectionHeader
                type={2}
                size={'extra-large'}
                additionalClass={'contact-banner__heading'}
                noLine
              >
                Tell us about your
                <WordAnimation />
              </SectionHeader>
              <ContactUsForm id={'form'} name={'contact-form-biz'} />
            </div>
          </>
        </Col>
        <Col
          md={3}
          lg={4}
          xl={4}
          className={'contact-page__sidebar'}
          style={{ backgroundColor: sidebarColor }}
        >
          <div className={'contact-page__sidebar-container'}>
            <div className={'contact-page__sidebar-envelope'}>
              <img
                src={getCloudinaryUrl(
                  'contact-page/contact-envelope.png',
                  'f_auto,q_auto,w_auto',
                )}
                alt={'contact-envelope'}
              />
            </div>
            <div className={'contact-page__sidebar-info'}>
              <Paragraph size={'medium'} additionalClass={'text--bold'}>
                BUSINESS
              </Paragraph>
              <div className={'contact-page__sidebar-business'}>
                <div className={'contact-page__sidebar-photo'}>
                  <img
                    src={getCloudinaryUrl(
                      'business-crew/klaudia-cembor.png',
                      'f_auto,q_auto,w_auto',
                    )}
                    alt={'business-consultant'}
                  />
                </div>
                <div className={'contact-page__sidebar-contact'}>
                  <Paragraph size={'medium'}>Klaudia Cembor</Paragraph>
                  <Paragraph
                    additionalClass={'contact-page__sidebar-contact-job'}
                  >
                    Business Development
                  </Paragraph>
                  <Link
                    to={'mailto:business@boldare.com'}
                    additionalClass={'contact-banner__mail'}
                  >
                    <Paragraph size={'medium'}>business@boldare.com</Paragraph>
                  </Link>
                </div>
              </div>
              <div className={'contact-page__sidebar-contact--phones'}>
                <FooterPhones extraMargin />
              </div>
              <Paragraph
                size={'medium'}
                additionalClass={'contact-page__sidebar-general text--bold'}
              >
                GENERAL
              </Paragraph>
              <Link
                to={'mailto:hello@boldare.com'}
                additionalClass={'contact-banner__mail'}
              >
                <Paragraph size={'medium'}>hello@boldare.com</Paragraph>
              </Link>
              <Paragraph
                size={'medium'}
                additionalClass={'contact-page__sidebar-general text--bold'}
              >
                CAREERS
              </Paragraph>
              <Link
                to={'mailto:career@boldare.com'}
                additionalClass={'contact-banner__mail'}
              >
                <Paragraph size={'medium'}>career@boldare.com</Paragraph>
              </Link>
              <Paragraph
                size={'medium'}
                additionalClass={'contact-page__sidebar-general text--bold'}
              >
                PUBLIC RELATIONS
              </Paragraph>
              <Link
                to={'mailto:career@boldare.com'}
                additionalClass={'contact-banner__mail'}
              >
                <Paragraph size={'medium'}>media@boldare.com</Paragraph>
              </Link>
            </div>
            {enableDecorations && (
              <div className={'contact-page__sidebar-rectangle'} />
            )}
          </div>
        </Col>
      </Row>
    </Section>
  ),
);

ContactBanner.propTypes = {
  enableDecorations: PropTypes.bool,
  color: PropTypes.string,
  sidebarColor: PropTypes.string,
  widerDisplay: PropTypes.bool,
};

ContactBanner.defaultProps = {
  enableDecorations: true,
  color: 'white',
  sidebarColor: 'seashell',
  widerDisplay: true,
};

export default ContactBanner;
