import React from 'react';
import { Link } from 'components';
import German from 'assets/images/contact/german-flag.svg';
import Netherlands from 'assets/images/contact/netherlands-flag.svg';
import USA from 'assets/images/contact/usa-flag.svg';
import WhatsAppIcon from 'assets/images/contact/whats-app.svg';
import './FooterPhones.scss';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';

const FooterPhones = ({ extraMargin = false }) => {
  const flagClass = extraMargin ? 'mini-flag extra-margin' : 'mini-flag';

  return (
    <div className={'footer-phones__top-data'}>
      <Link
        to={`tel:${CountryContactPhoneNumbers.USA.value}`}
        className={'footer-phones__top-data--phone'}
      >
        <img
          src={USA}
          alt={'USA Flag'}
          className={flagClass}
          loading={'lazy'}
        />
        <span id={'phones-phone-usa'} className={'text--black'}>
          {CountryContactPhoneNumbers.USA.displayValue}
        </span>
      </Link>
      <Link
        to={`tel:${CountryContactPhoneNumbers.Germany.value}`}
        className={'footer-phones__top-data--phone'}
      >
        <img
          src={German}
          alt={'German Flag'}
          className={flagClass}
          loading={'lazy'}
        />
        <span id={'footer-phones-german'} className={'text--black'}>
          {CountryContactPhoneNumbers.Germany.displayValue}
        </span>
      </Link>
      <Link
        to={`tel:${CountryContactPhoneNumbers.Netherlands.value}`}
        className={'footer-phones__top-data--phone'}
      >
        <img
          src={Netherlands}
          alt={'Netherlands Flag'}
          className={flagClass}
          loading={'lazy'}
        />
        <span id={'footer-phones-netherlands'} className={'text--black'}>
          {CountryContactPhoneNumbers.Netherlands.displayValue}
        </span>
      </Link>
      <Link
        to={'https://go.boldare.com/contact-us-whatsapp'}
        className={'footer-phones__top-data--phone'}
      >
        <img
          src={WhatsAppIcon}
          alt={'WhatsApp Icon'}
          className={flagClass}
          loading={'lazy'}
        />
        <span id={'footer-phones-whatsApp'} className={'text--black'}>
          Contact us via WhatsApp
        </span>
      </Link>
    </div>
  );
};

export default FooterPhones;
