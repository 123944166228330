export const sliderData = [
  {
    background:
      'img/OpenAI_Assistant_API_based_assistant_for_a_consstruction_materials_platform.png',
    caption: 'AI services',
    info: 'case study',
    description:
      'Empowering Users of a Building Materials Trading Platform with a Smart, AI-Powered Assistant',
    color: 'pigeon-post-light',
    link: '/work/case-study-building-materials-ai-assistant/',
  },
  {
    background: 'img/AI-powered-e-commerce_success_story.png',
    caption: 'AI services',
    info: 'case study',
    description:
      'AI and E-Commerce: Saving €150K While Boosting Sales and SEO',
    color: 'downy',
    link: '/blog/ai-e-commerce-saving-150k-euros-boosting-sales-seo/',
  },
  {
    background: 'img/proof_of_value_1.png',
    caption: 'AI services',
    info: 'case study',
    description:
      'Using an AI-Powered Proof of Concept for a Client from the Health Food Industry',
    color: 'violet-main',
    link: '/blog/ai-powered-poc-health-food-industry/',
  },
  {
    background: 'img/AI_POC_knowledge_base.jpg',
    caption: 'AI services',
    info: 'case study',
    description:
      'Demonstrating Viability with Proof of Concept: An AI-Powered Chatbot Case Study',
    color: 'yellow',
    link: '/work/ai-powered-chatbot-knowledge-base-case-study/',
  },
];
