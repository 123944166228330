import React, { PureComponent } from 'react';
import { kebabCase } from 'lodash';
import {
  Section,
  Heading,
  Paragraph,
  Button,
  Link,
  FooterPhones,
} from 'components';
import Logo from 'assets/images/logo-signet.svg';
import { getCloudinarySrcSet, getCloudinaryUrl } from 'config/helpers';
import IconSVG from '../IconSVG';
import config from '../../../meta/config';
import { data, paths } from './logic.js';
import './Footer.scss';

const Clutch = 'gatsby-cloudinary/Clutch_Badge.png';
const Goodfirms = 'gatsby-cloudinary/Goodfirms_Badge.png';
const Awwwards = 'gatsby-cloudinary/awwwards-badge-16.png';
const GermanDesignAward = 'gatsby-cloudinary/german-design-award_2x.png';
const FrenchCertificate = 'gatsby-cloudinary/French_Certificate_Badge.png';

const FrenchCerificateUrl =
  'https://entreprendre.service-public.fr/vosdroits/F35494?lang=en';

class Footer extends PureComponent {
  renderColumn(elements) {
    const items = elements;
    this.filteredPaths = paths.filter(item => items.includes(item.name));
    return this.renderNavItems();
  }

  renderNavItems = () =>
    this.filteredPaths.map(item => {
      const { link, name, children } = item;

      const renderChildren = () =>
        children.map(child => (
          <Link
            to={`${child.link}`}
            id={`footer-nav-${kebabCase(child.name.toLowerCase())}`}
            key={child.name}
            className={'hide-mobile'}
          >
            <Paragraph
              size={'default'}
              color={'white'}
              additionalClass={'services-item'}
            >
              {child.name}
            </Paragraph>
          </Link>
        ));

      return (
        <div key={item.name} className={'footer__menu-item'}>
          {children ? (
            <div className={'footer__menu-services'}>
              <Link
                id={`footer-nav-${kebabCase(name.toLowerCase())}`}
                to={link}
                key={`nav-item-${name}`}
                additionalClass={'footer__menu-services--main'}
              >
                <span className={'text--white'}>{name}</span>
              </Link>
              {renderChildren()}
            </div>
          ) : (
            <>
              {name === 'Privacy Policy' ? (
                <Link
                  id={`footer-nav-${kebabCase(name.toLowerCase())}`}
                  to={link}
                  key={`nav-item-${name}`}
                  rel={'nofollow'}
                >
                  <span className={'text--white footer__privacy-policy'}>
                    {name}
                  </span>
                </Link>
              ) : (
                <Link
                  id={`footer-nav-${kebabCase(name.toLowerCase())}`}
                  to={link}
                  key={`nav-item-${name}`}
                >
                  <span className={'text--white'}>{name}</span>
                </Link>
              )}
            </>
          )}
        </div>
      );
    });

  renderPlaces = () =>
    data.map(item => (
      <div key={item.title} className={'footer__info-place'}>
        <Paragraph
          size={'medium'}
          color={'white'}
          additionalClass={'footer__info-place--hq text--bold'}
        >
          {item.title}
        </Paragraph>
        <Paragraph color={'white'} additionalClass={'footer__info-address'}>
          <Link to={item.map}>
            <span
              id={`footer-map-${kebabCase(item.city.toLowerCase())}`}
              className={'text--white'}
            >
              {item.street}
              <br />
            </span>
          </Link>
          {item.postal}&nbsp;{item.city}
          <br />
          {item.country}
        </Paragraph>
      </div>
    ));

  render = () => (
    <Section
      color={'mine-shaft'}
      additionalClass={'section--footer'}
      fullwidth
    >
      <div className={'footer__top'}>
        <div className={'footer__top-quarter footer__top-quarter--career'}>
          <img
            loading={'lazy'}
            src={getCloudinaryUrl(
              'v1604573447/footer-img2.jpg',
              `f_auto,q_auto,w_1000,e_grayscale`,
            )}
            srcSet={getCloudinarySrcSet(
              '/e_grayscale/v1604573447/footer-img2.jpg',
              1000,
            )}
            alt={'Boldare Career'}
          />
          <div className={'footer__top-content'}>
            <Heading
              type={3}
              size={'medium-plus'}
              additionalClass={'footer__top-heading'}
            >
              Join our Team
            </Heading>
            <Link
              id={'footer-career'}
              additionalClass={'footer__top-link'}
              to={'/career/'}
            >
              <Button color={'white'}>Career &#8594;</Button>
            </Link>
          </div>
        </div>
        <div className={'footer__top-quarter footer__top-quarter--contact'}>
          <img
            loading={'lazy'}
            src={getCloudinaryUrl(
              'v1604573447/footer-img1.jpg',
              `f_auto,q_auto,w_1000,e_grayscale`,
            )}
            srcSet={getCloudinarySrcSet(
              'e_grayscale/v1604573447/footer-img1.jpg',
              1000,
            )}
            alt={'Boldare Contact'}
          />
          <div className={'footer__top-content'}>
            <Heading
              type={3}
              size={'medium-plus'}
              additionalClass={'footer__top-heading'}
            >
              Get in touch
            </Heading>
            <div className={'footer__top-details'}>
              <Link
                id={'footer-contact'}
                additionalClass={'footer__top-link'}
                to={'/contact/'}
              >
                <Button color={'white'}>Contact us &#8594;</Button>
              </Link>
              <FooterPhones />
            </div>
          </div>
        </div>
        <div className={'footer__top-quarter footer__top-quarter--bldr'}>
          <div className={'footer__top-content'}>
            <Link to={'/'} additionalClass={'footer__top-logo'}>
              <img
                src={Logo}
                className={'footer__top-bldr'}
                alt={'Digital product design company Boldare'}
                loading={'lazy'}
              />
            </Link>
            <Paragraph
              size={'medium'}
              color={'white'}
              additionalClass={'footer__top-contact'}
            >
              Digital product creators &amp; consultants
            </Paragraph>
          </div>
        </div>
        <div className={'footer__top-quarter footer__top-quarter--newsletter'}>
          <img
            loading={'lazy'}
            src={getCloudinaryUrl(
              'v1604573447/footer-img1.jpg',
              `f_auto,q_auto,w_1000,e_grayscale`,
            )}
            srcSet={getCloudinarySrcSet(
              'e_grayscale/v1604573447/footer-img1.jpg',
              1000,
            )}
            alt={'Boldare Newsletter'}
          />
          <div className={'footer__top-content'}>
            <div className={'footer__top-sm'}>
              <Link
                to={config.facebook}
                id={'footer-facebook'}
                rel={'nofollow'}
              >
                <IconSVG
                  name={'facebook-letter'}
                  size={'medium'}
                  color={'black'}
                />
              </Link>
              <Link
                to={config.linkedin}
                id={'footer-linkedin'}
                rel={'nofollow'}
              >
                <IconSVG
                  name={'linkedin-letter'}
                  size={'medium'}
                  color={'black'}
                />
              </Link>
              <Link
                to={config.instagram}
                id={'footer-instagram'}
                rel={'nofollow'}
              >
                <IconSVG name={'instagram'} size={'medium'} color={'black'} />
              </Link>
              <Link to={config.twitter} id={'footer-twitter'} rel={'nofollow'}>
                <IconSVG name={'twitter'} size={'medium'} color={'black'} />
              </Link>
              <Link to={config.behance} id={'footer-behance'} rel={'nofollow'}>
                <IconSVG name={'behance'} size={'medium'} color={'black'} />
              </Link>
              <Link
                to={config.dribbble}
                id={'footer-dribble'}
                rel={'nofollow'}
              >
                <IconSVG
                  name={'dribbble-mini'}
                  size={'medium'}
                  color={'black'}
                />
              </Link>
              <Link to={config.youtube} id={'footer-youtube'} rel={'nofollow'}>
                <IconSVG
                  name={'youtube-mini'}
                  size={'medium'}
                  color={'black'}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={'w-container'}>
        <footer id={'footer'} className={'footer'}>
          {!this.props.compact && (
            <>
              <div className={'footer__links'}>
                <nav className={'footer__menu'} aria-label={'main navigation'}>
                  <>
                    <div className={'footer__menu-column'}>
                      {this.renderColumn(['Services'])}
                    </div>
                    <div className={'footer__menu-column'}>
                      {this.renderColumn([
                        'About us',
                        'Resources',
                        'Contact',
                        'Blog',
                        'Career',
                        'New Normal',
                        'Boldare Boards',
                        'Privacy Policy',
                        'Newsroom',
                        'Agile Product Builders',
                        'Investor relations',
                      ])}
                    </div>
                  </>
                </nav>
                <div className={'footer__info'}>
                  <div className={'footer__info-places'}>
                    {this.renderPlaces()}
                  </div>
                  <div className={'footer__badges'}>
                    <Link
                      to={config.clutch}
                      id={'footer-clutch'}
                      rel={'nofollow'}
                    >
                      <img
                        src={getCloudinaryUrl(Clutch, 'f_auto,q_auto,h_70')}
                        className={'footer__logo'}
                        alt={'Clutch Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                    <Link
                      to={config.goodfirms}
                      id={'footer-goodfirms'}
                      rel={'nofollow'}
                    >
                      <img
                        src={getCloudinaryUrl(Goodfirms, 'f_auto,q_auto,h_70')}
                        className={'footer__logo'}
                        alt={'GoodFirms Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                    <Link
                      to={config.awwwards}
                      id={'footer-awwwards'}
                      rel={'nofollow'}
                    >
                      <img
                        src={getCloudinaryUrl(Awwwards, 'f_auto,q_auto,h_70')}
                        className={'footer__logo'}
                        alt={'Awwwards Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                    <Link to={config.gda} id={'footer-gda'} rel={'nofollow'}>
                      <img
                        src={getCloudinaryUrl(
                          GermanDesignAward,
                          'f_auto,q_auto,h_70',
                        )}
                        className={'footer__logo'}
                        alt={'German Design Awards Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          <Paragraph
            size={'small'}
            color={'white'}
            additionalClass={'footer__copyrights'}
          >
            &#169; {new Date().getFullYear()} Boldare. All rights reserved.
          </Paragraph>
          <p className={'footer__copyrights'}>
            Boldare S.A. z siedzibą w Gliwicach, przy ul. Zwycięstwa 52,
            zarejestrowana w Sądzie Rejonowym w Gliwicach, X Wydział
            Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000914518, NIP
            6312698829, REGON 38958555. Wysokość kapitału zakładowego i
            wpłaconego 100 000,00 zł.
          </p>
        </footer>
      </div>
    </Section>
  );
}

export default Footer;
