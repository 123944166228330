import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';

export function ResponseSendContactHeaderTwo({
  texts,
  phoneNumber,
  phoneNumberFormatted,
}) {
  return (
    <>
      {texts.map((text, index) => {
        return (
          <Fragment key={text}>
            {text} {index < texts.length - 1 && <br />}
          </Fragment>
        );
      })}
      <a href={`tel:${phoneNumber}`} className={'link__underline'}>
        {phoneNumberFormatted}
      </a>
    </>
  );
}

ResponseSendContactHeaderTwo.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string),
  phoneNumber: PropTypes.string,
  phoneNumberFormatted: PropTypes.string,
};

ResponseSendContactHeaderTwo.defaultProps = {
  texts: [
    'We will get to know your idea and get back to you within 24\u00A0business hours.',
    'If you need immediate assistance, call us on',
  ],
  phoneNumber: CountryContactPhoneNumbers.USA.value,
  phoneNumberFormatted: CountryContactPhoneNumbers.USA.displayValue,
};
