export const content = {
  services: {
    header: 'END-TO-END DIGITAL SERVICES',
    links: [
      {
        url: '/services/software-development-outsourcing',
        title: 'Software Development',
        description: 'Web development, Mobile Development, No Code Development, Custom Application Development',
        icon: 'development',
      },
      {
        url: '/ux-ui-design-consulting-services',
        title: 'Digital Design',
        description: 'Product Design, UI/UX Design, Design Systems Digital Prototyping',
        icon: 'business',
      },
      {
        url: '/services/ai-software-development-consulting',
        title: 'Generative AI',
        description: 'AI Prototyping and MVP Development, AI Software Development and Consulting, AI Integration and Deployment',
        icon: 'magnifier',
      },
      {
        url: '/blog/how-do-you-make-first-product-discovery-workshops',
        title: 'Product Innovation & Strategy',
        description: 'User Research & Testing, Product Discovery, Product Strategy & Roadmap, AI Strategy and Roadmap',
        icon: 'design',
      },
      {
        url: '/services/agile-coaching-services',
        title: 'Quality & Project Management',
        description: 'Quality Assurance, Agile Project Management, Product Management',
        icon: 'prototype',
      },
      {
        url: '/services/devops-consulting-services',
        title: 'DevOps & Infrastructure',
        description: 'Cloud Services, Continuous Integration and Continuous Deployment, Infrastructure Automation, Containerization',
        icon: 'mvp',
      },
      {
        url: '/work/cto-as-a-service-solves-the-problems-of-a-us-digital-product-company',
        title: 'Consulting & Scaling',
        description: 'Agile Coaching, Agile Teams Scaling, CTO as a Service',
        icon: 'pmf',
      },
      {
        url: '/services/code-audit',
        title: 'Testing & Quality',
        description: 'Automated Testing, Code Audit and UX Audit, Test Strategy and Planning',
        icon: 'scaling',
      },
    ],
  },
  products: {
    header: 'Full Cycle Product Development',
    description:
      'Avoid switching costs while your product grows. Partner with the company that’s small enough to care and big enough to scale',
    mainLink: {
      url: '/services/full-cycle-product-development',
      text: 'Discover all the benefits of FCPD',
    },
    links: [
      {
        url: '/services/full-cycle-product-development/prototyping',
        title: 'Digital Prototyping',
        description: 'If you want to quickly validate your idea',
        icon: 'prototype',
      },
      {
        url: '/services/mvp-development',
        title: 'Minimum Viable Product (MVP)',
        description:
          'If you want to avoid spending a big budget on a product that users don’t want',
        icon: 'mvp',
      },
      {
        url: '/services/product-market-fit-expanding-demand',
        title: 'Product-Market Fit',
        description:
          'If you need a team to work on an existing app, adding features that are in demand by users',
        icon: 'pmf',
      },
      {
        url: '/services/scaling-your-product-thriving-in-the-market',
        title: 'Product Scaling',
        description: 'If you want to handle more users or enter new markets',
        icon: 'scaling',
      },
    ],
  },
  scaling: {
    header: 'DEVELOPMENT CAPACITY SCALING',
    links: [
      {
        url: '/services/development-teams',
        title: 'Dedicated Teams',
        description: 'For specialized skills, faster time-to-market, and scalability beyond your in-house capabilities.',
      },
      {
        url: '/services/team-augmentation',
        title: 'Team Augmentation',
        description: 'Gain additional expertise or resources to meet business demands without hiring full-time staff.',
      },
      {
        url: '/services/product-design-and-development',
        title: 'Product Creation',
        description: 'To create a digital product from scratch, develop an application, or as part of corporate innovation.',
      },
    ],
  },
};
