/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';

import {
  Paragraph,
  Section,
  Button,
  Heading,
  SectionHeader,
  Link,
  IconSVG,
  HighlightNumber,
} from 'components';

import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';
import { scheduleMeetingUrl } from 'config/constants/links';
import { topics } from './logic.js';
import { generateHash, classNames } from '../../utils';

import './ContactFormServices.scss';

const ContactFormServices = ({
  name,
  title,
  description,
  descriptionTwo,
  button,
  textareaContent,
  id,
  meetButton,
  firstNameContent,
  companyEmailContent,
  privateDataInfo: PrivateDataInfoComponent,
  topicsData,
  responseButtonText,
  responseSendContactHeaderTwo: ResponseSendContactHeaderTwo,
  responseSendContactHeader: ResponseSendContactHeader,
  theme,
  pardotLink,
}) => {
  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
    contactTopic: [],
    wantToSignNDA: false,
  });
  const isRenewableEnergyIndustryPage = id === 'renewable-energy-industry-contact-form';

  const contactPerson = isRenewableEnergyIndustryPage
  ? {
      fullName: "Anna Sokołow",
      picture: { cloudinary: 'img/renewable-energy-industry/anna-sokolow.png' }

  }
  : {
      fullName: "Klaudia Cembor",
      picture: { cloudinary: 'business-crew/klaudia-cembor.png' }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.search.includes('?thank-you')) {
      setIsThankYouPage(true);
      scroller.scrollTo(id, { offset: -80 });
    }
  }, [id]);

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setForm(prevForm => ({
      ...prevForm,
      [fieldName]: value,
    }));
  };

  const handleNdaCheckboxChange = e => {
    e.preventDefault();
    setForm(prevForm => ({
      ...prevForm,
      wantToSignNDA: !prevForm.wantToSignNDA,
    }));
  };

  const handleSubmitForm = e => {
    e.preventDefault();

    const body = new URLSearchParams(form).toString();

    setIsLoading(true);

    fetch(pardotLink, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then(() => {
        const url = new URL(window.location.href);
        url.searchParams.set('thank-you', '');
        url.searchParams.set('connectionId', generateHash());
        window.location.href = url.toString();
      })
      .catch(error => console.log(error));
  };

  const checkTopic = filter => {
    const key = 'contactTopic';
    setForm(prevForm => {
      if (prevForm[key].includes(filter)) {
        return {
          ...prevForm,
          [key]: prevForm[key].filter(value => value !== filter),
        };
      }
      return {
        ...prevForm,
        [key]: filter ? [...prevForm[key], filter] : [],
      };
    });
  };

  const renderTopics = data => {
    const topicArray = form.contactTopic;

    const filters = data.map(filter => (
      <li
        key={`filter-${filter.filterName}`}
        className={`form-services__filters-item ${
          topicArray.includes(filter.filterName)
            ? 'form-services__filters-item--active'
            : ''
        }`}
        onClick={() => {
          checkTopic(filter.filterName);
        }}
      >
        <div className={'form-services__filters-item-content'}>
          <span className={'form-services__filters-item-heading'}>
            {filter.filterName}
          </span>
          {topicArray.includes(filter.filterName) && (
            <span className={'form-services__filters-option'}>
              <IconSVG
                name={'form-rocket'}
                size={'large'}
                color={'mine-shaft'}
                additionalClass={'form-services__filters-icon'}
              />
            </span>
          )}
        </div>
      </li>
    ));

    return <ul className={'form-services__filters-list'}>{filters}</ul>;
  };

  return (
    <Section
      id={id}
      color={theme === 'pink' ? 'cinderella' : 'yellow'}
      additionalClass={'form-services'}
    >
      {!isThankYouPage ? (
        <>
          <div className={'form-services__intro'}>
            <div className={'form-services__intro-text'}>
              <Heading>{title}</Heading>
              <Heading
                additionalClass={'form-services__intro-text-description'}
              >
                {description}&nbsp;
                <span className={'form-services__intro-text-bold'}>
                  two simple steps!
                </span>
              </Heading>
            </div>
            <div className={'form-services__intro-business'}>
              <div className={'form-services__intro-photo'}>
                <img
                  src={getCloudinaryUrl(
                    contactPerson.picture.cloudinary,
                    'f_auto,q_auto,w_auto',
                  )}
                  alt={'business-consultant'}
                />
              </div>
              <div className={'form-services__intro-contact'}>
                <Paragraph
                  additionalClass={'form-services__intro-contact-job'}
                >
                  CONTACT PERSON
                </Paragraph>
                <Paragraph
                  size={'medium'}
                  additionalClass={'form-services__intro-contact-name'}
                >
                  {contactPerson.fullName}
                </Paragraph>
                <Link to={'mailto:business@boldare.com'}>
                  <Paragraph size={'medium'}>business@boldare.com</Paragraph>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <SectionHeader
            additionalClass={'form-services__message-sent'}
            size={'big'}
            lineColor={'violet'}
            fixed
            noLine
          >
            <ResponseSendContactHeader />
          </SectionHeader>
          <Heading
            size={'medium-plus'}
            additionalClass={'form-services__description'}
          >
            <ResponseSendContactHeaderTwo />
          </Heading>
          {meetButton ? (
            <Button
              onClick={() => {
                scroller.scrollTo('connect-page__consultants', {
                  spy: false,
                  smooth: true,
                  duration: 700,
                  offset: -40,
                });
              }}
              color={'black'}
              id={'form-services-thank-you-button'}
              ariaName={'Contact button'}
              transparent
            >
              {responseButtonText}
            </Button>
          ) : (
            <Link to={'/contact'}>
              <Button
                color={'black'}
                id={'form-services-thank-you-button'}
                ariaName={'Contact button'}
                transparent
              >
                {responseButtonText}
              </Button>
            </Link>
          )}
        </>
      )}

      <>
        <form
          name={name}
          className={classNames('form-services__form', {
            'form-services__form--hidden': isThankYouPage,
          })}
          method={'post'}
          onSubmit={handleSubmitForm}
        >
          <div className={classNames('form-services__filters')}>
            <div className={'form-services__filters-title'}>
              <HighlightNumber fill={'#7563E7'} textColor={'white'}>
                1.
              </HighlightNumber>
              <Heading
                size={'medium-plus-small'}
                additionalClass={'form-services__filters-heading'}
              >
                {descriptionTwo}
              </Heading>
            </div>
            {renderTopics(topicsData.topics)}
          </div>

          <div className={'form-services__personal-data'}>
            <div className={'form-services__personal-data-title'}>
              <HighlightNumber fill={'#7563E7'} textColor={'white'}>
                2.
              </HighlightNumber>
              <Heading
                size={'medium-plus-small'}
                additionalClass={'form-services__filters-heading'}
              >
                Add more details and send the message
              </Heading>
            </div>

            <div className={'input-container'}>
              <input
                type={'text'}
                name={'name'}
                id={'form-name'}
                onChange={e => handleChange(e, 'name')}
                required
              />
              <label htmlFor={'form-name'}>{firstNameContent}</label>
            </div>
            <div className={'input-container'}>
              <input
                type={'email'}
                name={'email'}
                id={'form-email'}
                className={classNames({
                  'input--filled': form.email,
                })}
                onChange={e => handleChange(e, 'email')}
                required
              />
              <label htmlFor={'form-email'}>{companyEmailContent}</label>
            </div>
            <div className={'textarea-container'}>
              <textarea
                name={'message'}
                id={'form-textarea'}
                onChange={e => handleChange(e, 'message')}
                required
              />
              <label htmlFor={'form-textarea'}>{textareaContent}</label>
            </div>
            <div className={'form-services__info'}>
              <div className={'form-services__info-container'}>
                <button
                  className={`form-services__info-checkbox ${
                    form.wantToSignNDA ? '--marked' : ''
                  }`}
                  onClick={handleNdaCheckboxChange}
                  aria-label={'NDA checkbox'}
                  type={'button'}
                  name={'wantToSignNDA'}
                >
                  <IconSVG
                    name={'checkmark'}
                    color={'white'}
                    additionalClass={'form-services__info-icon'}
                  />
                </button>
                <span className={'form-services__info-label'}>
                  I want to sign an NDA.
                </span>
              </div>

              <Paragraph size={'small-plus'}>
                <PrivateDataInfoComponent />
              </Paragraph>
              <Button
                id={`form-services-${name}-submit`}
                additionalClass={classNames(
                  'contact-button',
                  'form-services__button',
                  { loading: isLoading },
                )}
                type={'submit'}
                value={'submit'}
                color={'violet'}
                ariaName={'Send message button'}
                disabled={isLoading}
                loading
              >
                {button}
              </Button>
            </div>
          </div>
        </form>
      </>
    </Section>
  );
};

ContactFormServices.propTypes = {
  description: PropTypes.string,
  descriptionTwo: PropTypes.string,
  button: PropTypes.string,
  pardotLink: PropTypes.string,
  lang: PropTypes.string,
  textareaContent: PropTypes.string,
  firstNameContent: PropTypes.string,
  companyEmailContent: PropTypes.string,
  phoneText: PropTypes.string,
  emailText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  designMail: PropTypes.bool,
  timeline: PropTypes.bool,
  meetButton: PropTypes.bool,
  privateDataInfo: PropTypes.func,
  topicsData: PropTypes.object,
  responseSendContactHeader: PropTypes.func,
  responseSendContactHeaderTwo: PropTypes.func,
  responseButtonText: PropTypes.string,
  theme: PropTypes.string,
};

ContactFormServices.defaultProps = {
  title: 'How can we help you?',
  description: `Let us know what your needs are, and we will do our best to help you. It's just`,
  descriptionTwo: "I'm interested in...",
  button: 'SEND',
  pardotLink: 'https://go.boldare.com/l/688533/2019-02-28/x5r',
  lang: 'en',
  textareaContent: 'Anything more we should know or prepare?',
  firstNameContent: 'First Name',
  companyEmailContent: 'Company email',
  phoneText: 'Phone:',
  emailText: 'Email:',
  timeline: false,
  meetButton: false,
  designMail: false,
  privateDataInfo: () => (
    <>
      Your data are safe with us. We take your privacy seriously and we will
      never share your data with anyone. Read on our{' '}
      <Link to={'/privacy-policy/'} id={'contact-biz-privacy-policy'}>
        Privacy Policy
      </Link>{' '}
      to learn more.
    </>
  ),
  topicsData: {
    topics,
  },
  responseButtonText: 'Meet our team',
  theme: 'yellow',
  responseSendContactHeader: () => (
    <>
      Thank you!
      <br />
      Your message has been sent
    </>
  ),
  responseSendContactHeaderTwo: () => (
    <>
      We will get to know your idea and get back to you within 24&nbsp;business
      hours.
      <br />
      If you need immediate assistance, call us on{' '}
      <a href={`tel:${CountryContactPhoneNumbers.USA.value}`} className={'link__underline'}>
        {CountryContactPhoneNumbers.USA.displayValue}
      </a>{' '}
      or&nbsp;schedule a&nbsp;meeting{' '}
      <a
        href={scheduleMeetingUrl}
        className={'link__underline'}
      >
        here
      </a>
      !
    </>
  ),
};

export default ContactFormServices;
